import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setCurrentPartnerName, fetchPartnerInfo, setIsAdvisor } from '@store/slices/partnerSlice';
import { setCurrentStep } from '@store/slices/stepsSlice';
import { getUrlParam } from '@frontend-components/utils/urls';
import { useToggleEstateStep } from '@hooks';
import Header from '@components/Header';
import PageTop from '@components/PageTop';
import ErrorBoundary from '@frontend-components/components/ErrorBoundary';
import Advisor from '@steps/Advisor';
import Spinner from '@frontend-components/components/Spinner';
import StepsWrapper from '@components/StepWrapper';
import UserDetails from '@steps/UserDetails';
import Marital from '@steps/Marital';
import RealEstate from '@steps/RealEstate';
import Consents from '@steps/Consents';
import Booking from '@steps/Booking';
import AdditionalInfo from '@steps/AdditionalInfo';
import FinalStep from '@steps/FinalStep';
import Message from '@frontend-components/components/Message';
import ProductSelector from '@components/ProductSelector';
import { Steps } from '@constants';
const url = window.location.pathname.split('/');
const partnerName = url[url.length - 1];
const advisorParam = getUrlParam(window.location.href, 'advisor');
const App = () => {
    var _a;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { completedSteps, isFinalStep, isLoading, isError, partnerInfo, isAdvisor } = useSelector(({ steps, partner }) => ({
        completedSteps: steps.completedSteps,
        isFinalStep: steps.isFinalStep,
        isLoading: partner.isPartnerInfoLoading,
        isError: partner.isPartnerInfoFailure,
        partnerInfo: partner.partnerInfo,
        isAdvisor: partner.isAdvisor
    }), shallowEqual);
    useToggleEstateStep();
    useEffect(() => {
        dispatch(setCurrentPartnerName(partnerName));
        dispatch(fetchPartnerInfo(partnerName));
        dispatch(setIsAdvisor(advisorParam !== '0'));
    }, []);
    const forceAdvisor = (_a = (!isAdvisor && (partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.showAdvisorOnUserOnboarding))) !== null && _a !== void 0 ? _a : false;
    useEffect(() => {
        if (!isLoading && partnerInfo != null) {
            if (!isAdvisor && (partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.referralWithoutAdvisorAllowed) != null && !partnerInfo.referralWithoutAdvisorAllowed) {
                window.location.replace(`/${partnerInfo.id}`);
                return;
            }
            const startStep = isAdvisor || forceAdvisor ? Steps.ADVISOR : Steps.USER_DETAILS;
            dispatch(setCurrentStep(startStep));
        }
    }, [isLoading, partnerInfo, isAdvisor]);
    const errorElement = isError ? _jsx(StepsWrapper, { children: _jsx(Message, { type: "error", children: t('global.errors.genericError') }) }) : null;
    const loadingElement = (isLoading && !isError) ? _jsx(StepsWrapper, { children: _jsx(Spinner, { position: "center" }) }) : null;
    const stepsElement = isFinalStep ? (_jsx(FinalStep, {})) : (_jsxs(_Fragment, { children: [_jsx(PageTop, { partnerName: partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.name, isAdvisor: isAdvisor }), errorElement, loadingElement, !isLoading && _jsx(ProductSelector, {}), completedSteps.includes(Steps.ADVISOR) ? _jsx(ErrorBoundary, { children: _jsx(Advisor, { forceAdvisor: forceAdvisor }) }) : null, completedSteps.includes(Steps.USER_DETAILS) ? _jsx(ErrorBoundary, { children: _jsx(UserDetails, {}) }) : null, completedSteps.includes(Steps.MARITAL) ? _jsx(ErrorBoundary, { children: _jsx(Marital, {}) }) : null, completedSteps.includes(Steps.REAL_ESTATE) ? _jsx(ErrorBoundary, { children: _jsx(RealEstate, {}) }) : null, completedSteps.includes(Steps.BOOKING) ? _jsx(ErrorBoundary, { children: _jsx(Booking, {}) }) : null, completedSteps.includes(Steps.ADDITIONAL_INFO) ? _jsx(ErrorBoundary, { children: _jsx(AdditionalInfo, {}) }) : null, completedSteps.includes(Steps.CONSENTS) ? _jsx(ErrorBoundary, { children: _jsx(Consents, {}) }) : null] }));
    return (_jsxs(_Fragment, { children: [_jsx(Header, { logoUrl: partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.logoFullPath }), stepsElement] }));
};
export default App;
