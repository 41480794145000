import React, { FC, StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { sentryInit } from '@frontend-components/utils/sentry'
import store from './src/store/store'

import App from './src/App'

import '@frontend-components/css/global.scss'
import './i18n'

const { MODE, VITE_SENTRY_URL } = import.meta.env

if (MODE === 'production' && VITE_SENTRY_URL != null) {
  sentryInit(VITE_SENTRY_URL)
}

const AppToRender: FC = () => (
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
)

const targetContainer = document.getElementById('reference-spa-wrapper')

if (targetContainer != null) {
  const root = createRoot(targetContainer)
  root.render(<AppToRender />)
}
