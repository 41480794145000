import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from '@hooks';
import { useSelector, shallowEqual } from 'react-redux';
import Button from '@frontend-components/components/Button';
import styles from './ProductSelector.module.scss';
const ProductSelector = () => {
    var _a;
    const { t } = useTranslation();
    const { partnerInfo } = useSelector(({ partner }) => ({ partnerInfo: partner.partnerInfo }), shallowEqual);
    if ((partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.additionalProducts) == null || ((_a = partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.additionalProducts) === null || _a === void 0 ? void 0 : _a.length) === 0)
        return null;
    const filteredProducts = partnerInfo.additionalProducts.filter((product) => product.active && product.hubspotFormLink && product.hubspotFormLink.length > 0);
    if (filteredProducts.length === 0)
        return null;
    return (_jsxs("div", { className: "container", children: [_jsx("h2", { className: styles.title, children: t('productSelect.title') }), _jsxs("div", { className: styles.wrapper, children: [_jsx(Button, { children: t('productSelect.family_and_estate') }), filteredProducts.map((product) => _jsx(Button, { kind: "secondary", href: product.hubspotFormLink, children: t(`productSelect.${product.name}`) }, product.name))] })] }));
};
export default ProductSelector;
